import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SCREEN_SIZE, STYLES } from '../../../style'
import { TwoColumnListItemProp } from './TwoColumnListItemProp'

const StyledTwoColumnListItem = styled.div`
  margin: 5rem 0;
  ${SCREEN_SIZE.LG} {
    flex: 0 0 50%;
    margin: 2.5rem 0;
  }
`;

const TwoColumnItemContent = styled.div`
  max-width: 30rem;
  margin: 0 auto;
  ${SCREEN_SIZE.LG} {
    padding: 0 2rem;
  }
`;

const TwoColumnImageContainer = styled.div`
  height: 11.25rem;
`;

const TwoColumnImage = styled.img`
  display: block;
  margin: 0 auto;
  max-width: 12rem;
  max-height: 10rem;
`;

const TwoColumnTitle = styled.h5`
  font-size: ${STYLES.FONT_SIZE_LG};
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
`;

const TwoColumnDescription = styled.div`
  line-height: ${STYLES.LINE_HEIGHT_MD};
  font-size: ${STYLES.FONT_SIZE_SM};
  color: ${STYLES.COLOR_DOVE_GRAY};
  text-align: center;
`;

const TwoColumnListItem = ({
  image,
  title,
  description,
}) => (
  <StyledTwoColumnListItem>
    <TwoColumnImageContainer>
      <TwoColumnImage src={image} />
    </TwoColumnImageContainer>
    <TwoColumnItemContent>
      <TwoColumnTitle>{title}</TwoColumnTitle>
      <TwoColumnDescription>{description}</TwoColumnDescription>
    </TwoColumnItemContent>
  </StyledTwoColumnListItem>
);

TwoColumnListItem.propTypes = TwoColumnListItemProp;

export { TwoColumnListItem };
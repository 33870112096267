import React from 'react';
import styled from 'styled-components';
import {
  Banner, BannerContent, BannerDescription, BannerContentItem, BannerTitle, WatchVideoBannerButton,
} from '../../shared/Banner';
import { PageImage } from '../../shared/PageImage';
import BusinessesSupportBannerIconSource from './images/businessSupportBannerIcon.svg';
import { STYLES } from '../../../style';

const StyledBusinessesSupportBanner = styled(Banner)``;

const BannerImage = styled(PageImage)`
`;

const BusinessesSupportBanner = () => (
  <StyledBusinessesSupportBanner color={STYLES.PAGE_BUSINESSES_SUPPORT_BANNER_COLOR}>
    <BannerContent>
      <BannerContentItem>
        <BannerImage src={BusinessesSupportBannerIconSource} />
      </BannerContentItem>
      <BannerContentItem>
        <BannerTitle>HERE WHEN YOU NEED US</BannerTitle>
        <BannerDescription>
          We're here for you when you need us the most. Sometimes things don't
          go according to plan, but we promise to make right, right now, every
          time.
        </BannerDescription>
        <WatchVideoBannerButton />
      </BannerContentItem>
    </BannerContent>
  </StyledBusinessesSupportBanner>
);

export { BusinessesSupportBanner };
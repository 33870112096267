import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Layout } from '../../components/shared/layout';
import { Page } from '../../components/shared/Page';
import { BusinessesSupportBanner } from '../../components/modules/BusinessesSupportPage';
import { DownloadMerchantsApp } from '../../components/modules/common/DownloadHandCarryApp';
import { BusinessNavigation } from '../../components/modules/common/BusinessNavigation';
import { LocationProp } from '../../components/shared/props/LocationProp';
import { SupportList } from '../../components/modules/BusinessesSupportPage/SupportList';

const StyledBusinessSupportPage = styled(Layout)`
  text-align: center;
`;

const BusinessSupportPage = ({
  location: { pathname },
}) => (
  <StyledBusinessSupportPage headerTransparent>
    <Page>
      <BusinessesSupportBanner />
      <SupportList />
      <BusinessNavigation pathname={pathname} />
      <DownloadMerchantsApp />
    </Page>
  </StyledBusinessSupportPage>
);

BusinessSupportPage.propTypes = {
  location: PropTypes.shape(LocationProp).isRequired,
};

export default BusinessSupportPage;

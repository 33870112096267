import React from 'react';
import HeadsetImageSource from './images/headset.svg';
import HandshakeImageSource from './images/handshake.svg';
import TouchImageSource from './images/touch.svg';
import MoneyBackImageSourc from './images/shield_money.svg';
import { TwoColumnList } from '../../shared/TwoColumnList';

const items = [
  { image: HeadsetImageSource, title: '24 hour support team', description: 'Our support team is ready to help 24 hours a day. We’re based in Japan and are always online.  You’ll never have to worry about being left alone.' },
  { image: HandshakeImageSource, title: 'No questions asked', description: 'If you say it, we beleive it. Hand Carry will never make you feel like you’re inconveniencing us, we promise hassle free claims without making you feel like you’re in the spot light.' },
  { image: TouchImageSource, title: 'Hassle free claims', description: 'Hand Carry promises you won’t have to chase us and waste your time solving any issues you may have. We won’t let you or your customers down.' },
  { image: MoneyBackImageSourc, title: 'Money back and more', description: 'In the event something does go wrong, we’re going to compensate and fix it immediately, the paperwork can wait. It’s yours and our reputation on the line.' },
];

const SupportList = () => <TwoColumnList items={items} />;

export { SupportList };
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TwoColumnListItem } from './TwoColumnListItem';
import { TwoColumnListItemProp } from './TwoColumnListItemProp';
import { SCREEN_SIZE } from '../../../style';
import { Centralized } from '../layout';

const StyledCommuterBenefitList = styled(Centralized)`
  ${SCREEN_SIZE.LG} {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
  }
`;

const TwoColumnList = ({
  items,
}) => (
  <StyledCommuterBenefitList>
    { items.map(({ image, title, description }) => (
      <TwoColumnListItem key={title} image={image} title={title} description={description} />
    ))}
  </StyledCommuterBenefitList>
);

TwoColumnList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(TwoColumnListItemProp)).isRequired,
};

export { TwoColumnList };